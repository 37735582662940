import React, { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import Link from '@components/Link';
import { Trans } from '@lingui/react';
import { Box } from '@components/layout/Grid';
import Span from '@components/layout/Span';
import ThumbnailWithRatio from '@components/ThumbnailWithRatio';
import { P } from '@components/typography/Paragraph';
import InstructorModalSkeleton from '@skeletons/InstructorModalSkeleton';
import { Body, Footer, Header, Modal } from './base';
import emptyResults from '@components/EmptyResults';
import BrokenFileSVG from '@assets/BrokenFileIcon';
import LanguageContext from '@lib/contexts/languageContext';
import { useModalInstructorLazyQuery } from '@gql/generated';

export const ButtonSC = styled.a`
  display: block;
  width: 100%;
  text-align: center;
  margin: 0;
  color: white;
  margin-left: 5px;
  margin-right: 5px;
  padding: 12px 25px;
  border-radius: 2px;
  border: 1px solid ${props => props.theme.colors.primary['blue-2']};
  background-color: ${props => props.theme.colors.primary['blue-2']};
  font-weight: 600;
  color: #fff;
  text-decoration: none;
  font-size: 13px;
  cursor: pointer;
`;

const EmptyResults = styled(emptyResults)`
  text-align: center;
`;

const BrokenFile = styled(BrokenFileSVG)`
  width: 100px;
  height: 100px;
  fill: ${({ theme }) => theme.colors.neutrals['gray-3']};
`;

type Props = {
  slug: string;
  children(arg0: Function): React.ReactNode;
};

const InstructorModal = (props: Props) => {
  const lang = useContext(LanguageContext);
  const [open, setOpen] = useState(false);
  const router = useRouter();
  const [loadInstructor, { loading, data }] = useModalInstructorLazyQuery({
    variables: { id: props.slug },
  });

  const handleOpenModal = async () => {
    await loadInstructor();
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const description = useMemo(() => {
    if (data?.instructor?.instructorPage?.description) {
      const div = document.createElement('div');
      div.innerHTML = data?.instructor.instructorPage.description;
      div.querySelectorAll('a').forEach(anchor => {
        anchor.setAttribute('target', '_blank');
        anchor.setAttribute('rel', 'noopener noreferrer nofollow');
      });
      return div.innerHTML;
    }
  }, [data?.instructor.instructorPage.description]);

  let content = null,
    footer = null;
  if (loading) {
    content = <InstructorModalSkeleton />;
  } else if (data?.instructor) {
    const { instructor } = data;
    content = (
      <div
        // because we use modal inside cards & cards are wrapper in links
        // we have to stop propagation to prevent the link from triggering..
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <Box mb={30}>
          <ThumbnailWithRatio path={instructor.assets.externalImage} />
        </Box>
        <Box mb={30}>
          <P color="#242424">
            <Span bold color="#242424">
              <Trans id="teacher_modal.speciality" message={'Speciality'} />:
            </Span>{' '}
            {instructor.speciality}
          </P>
          <P color="#242424">
            <Span bold color="#242424">
              <Trans id="teacher_modal.location" message={'Location'} />:
            </Span>{' '}
            {instructor.location}
          </P>
          <P color="#242424">
            <Span bold color="#242424">
              <Trans id="teacher_modal.homepage" message={'Homepage'} />:
            </Span>{' '}
            <a
              href={instructor.webpage}
              target="_blank"
              rel="nofollow noreferrer noopener"
            >
              {instructor.webpage}
            </a>
          </P>
        </Box>
        <Box mb={30}>
          <P
            className="Instructor--Desc"
            lineHeight="23px"
            color="#242424"
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </Box>
      </div>
    );
    footer =
      router?.pathname !== '/[lang]/team/[slug]' ? (
        <Footer>
          <ButtonSC href={`/${lang}/team/${instructor.slug}`} target="_blank">
            <Trans id="teacher_modal.go_to_profile" />
          </ButtonSC>
        </Footer>
      ) : null;
  } else {
    content = (
      <EmptyResults
        title=""
        description={
          <Trans
            id="public_profile.error_msg"
            message={`Sorry, it seems like there's an issue displaying the profile. Please try again in a bit`}
          />
        }
      >
        <BrokenFile />
      </EmptyResults>
    );
  }

  return (
    <>
      {props.children(handleOpenModal)}
      <Modal
        open={open}
        handleClose={handleCloseModal}
        customWidth="600px"
        data-testid={props['data-testid']}
      >
        <Header className="!pb-4" handleClose={handleCloseModal} />
        <Body className="!pt-0" pushFooterDown={120}>
          {content}
        </Body>
        {footer}
      </Modal>
    </>
  );
};

export default InstructorModal;
